<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票号码">
              <a-input v-model="queryParam['%invoice_number']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票代码">
              <a-input v-model="queryParam['%invoice_code']"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '新建发票' : '编辑发票'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="发票号码" prop="invoice_number">
              <a-input v-model="form.invoice_number"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="开票日期" prop="invoice_date">
              <a-date-picker style="width: 100%" v-model="form.invoice_date" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="校验码">
              <a-input v-model="form.check_code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="发票代码">
              <a-input v-model="form.invoice_code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="发票类型">
              <a-select v-model="form.invoice_type">
                <a-select-option :key="0">普通发票</a-select-option>
                <a-select-option :key="1">专用发票</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="发票金额">
              <a-input v-model="form.amount" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税率">
              <a-input v-model="form.tax_rate" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税额">
              <a-input v-model="form.tax" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="登记日期">
              <a-date-picker style="width: 100%" v-model="form.register_date" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import moment from 'moment'
import { deleteCommonInfo, getCommonPage, saveCommonInfo, updateCommonInfo } from '@/api/common'
export default {
  components: {
    STable
  },
  watch: {
    'form.amount': function (val) {
      this.form.tax = val * this.form.tax_rate
    },
    'form.tax_rate': function (val) {
      this.form.tax = val * this.form.amount
    }
  },
  data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '发票号码',
          dataIndex: 'invoice_number',
          width: 150
        },
        {
          title: '开票日期',
          dataIndex: 'invoice_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '校验码',
          dataIndex: 'check_code',
          width: 100
        },
        {
          title: '发票代码',
          dataIndex: 'invoice_code',
          width: 150
        },
        {
          title: '发票类型',
          dataIndex: 'invoice_type',
          width: 100,
          extra: {
            defaultValue: 0,
            type: 'select',
            options: [
              {
                name: '普通发票', value: 0
              },
              {
                name: '专用发票', value: 1
              }
            ]
          }
        },
        {
          title: '发票金额',
          dataIndex: 'amount',
          width: 120
        },
        {
          title: '税额',
          dataIndex: 'tax',
          width: 100
        },
        {
          title: '登记日期',
          dataIndex: 'register_date',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD')
          },
          width: 120
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        invoice_number: [
          { required: true, message: '请填写发票号码', trigger: 'blur' }
        ]
      },
      selectedRowKeys: [],
      loadData: parameter => {
        return getCommonPage(this.moduleName, Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      form: {
        invoice_number: '',
        invoice_date: '',
        check_code: '',
        invoice_code: '',
        invoice_type: 0,
        amount: '',
        tax_rate: '',
        tax: '',
        register_date: ''
      },
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      moduleName: 'invoice_registration'
    }
  },
  methods: {
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        invoice_number: '',
        invoice_date: '',
        check_code: '',
        invoice_code: '',
        invoice_type: 0,
        amount: '',
        tax_rate: '',
        tax: '',
        register_date: ''
      }
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    handleNameChange() {
      this.form.abbr = getFirstLetter(this.form.name)
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.formVisible = true
      this.form = record
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCommonInfo(this.moduleName, { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = saveCommonInfo
          if (this.modalFlag === 'update') {
            func = updateCommonInfo
          }
          this.form.invoice_date = this.form.invoice_date ? moment(this.form.invoice_date).valueOf() : null
          this.form.register_date = this.form.register_date ? moment(this.form.register_date).valueOf() : null
          func(this.moduleName, this.form).then(_ => {
            this.confirmLoading = false
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
            })
            if (this.modalFlag === 'create') {
              this.$refs.table.refresh(true)
            }
          }).catch(_ => {
            this.formVisible = false
            this.confirmLoading = false
          })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
